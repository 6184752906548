import { v4 as getGeneratedUuid } from 'uuid';

import { MessageUuid, WidgetUuid } from './types';

const REJECT_TIMEOUT_DEFAULT = 30_000;

export const messageType = Object.freeze({
  authToken: 'authToken',
  colorScheme: 'colorScheme',
  currentUser: 'currentUser',
  documentSize: 'documentSize',
});

const messageTypes = Object.values(messageType);

export type MessageType = typeof messageTypes[number];

export type MessageData<T> = {
  widgetUuid: WidgetUuid;
  messageUuid?: MessageUuid;
  messageType: MessageType;
  payload?: T;
};

export const getUuid = (): string => window.name;

export const sendMessageToDispatcher = <T = string>(params: MessageData<T>) => {
  const message: MessageData<T> = {
    messageUuid: getGeneratedUuid(),
    ...params,
    widgetUuid: getUuid(),
  };

  window?.top?.postMessage(message, '*');
};

export interface SendMessageToDispatcherAsync<T = string>
  extends Omit<MessageData<T>, 'widgetUuid' | 'messageUuid'> {
  rejectTimeout?: number;
}

export const sendMessageToDispatcherAsync = <T, R = unknown>(params: SendMessageToDispatcherAsync<T>) => {
  const { rejectTimeout = REJECT_TIMEOUT_DEFAULT, ...rest } = params;
  const messageUuid = getGeneratedUuid();

  const message: MessageData<T> = {
    widgetUuid: getUuid(),
    messageUuid,
    ...rest,
  };

  return new Promise<MessageData<R>>((resolve, reject) => {
    window?.top?.postMessage(message, '*');

    window.addEventListener('message', (event: MessageEvent<MessageData<R>>) => {
      const { data } = event;
      const { messageType: currentMessageType, messageUuid: currentMessageUuid } = data;

      const isMatchMessageType = Boolean(messageType[currentMessageType]);
      const isMatchMessageUuid = currentMessageUuid === messageUuid;

      if (isMatchMessageType && isMatchMessageUuid) {
        resolve(data);
      }
    });

    setTimeout(() => reject(messageUuid), rejectTimeout);
  });
};
