import { MessageType, MessageData, messageType } from './message';

type Event = MessageType;
type PayloadFn<T> = (payload: MessageData<T>) => void;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ListenersHashMap<T = any> = Map<Event, Set<PayloadFn<T>>>;

export class VKPHEmitter {
  private listenersHashMap: ListenersHashMap;

  constructor() {
    this.listenersHashMap = new Map();
  }

  init() {
    const listener = (event: MessageEvent<MessageData<string>>) => {
      const { data } = event;
      const { messageType: currentMessageType } = data;

      if (messageType[currentMessageType]) {
        this.emit(currentMessageType, data);
      }
    };

    window.addEventListener('message', listener);
  }

  on<T>(event: Event, fn: PayloadFn<T>) {
    let listeners = this.listenersHashMap.get(event);

    if (!listeners) {
      listeners = new Set<PayloadFn<T>>();
      this.listenersHashMap.set(event, listeners);
    }

    listeners.add(fn);

    return () => {
      this.off(event, fn);
    };
  }

  off<T>(event: Event, fn: PayloadFn<T>) {
    const listeners = this.listenersHashMap.get(event);

    if (listeners) {
      listeners.delete(fn);
    }

    if (listeners && listeners.size === 0) {
      this.listenersHashMap.delete(event);
    }
  }

  emit<T>(event: Event, payload: MessageData<T>) {
    const listeners = this.listenersHashMap.get(event);

    if (listeners) {
      listeners.forEach((listener) => listener(payload));
    }
  }

  removeAllListeners(event: Event) {
    this.listenersHashMap.delete(event);
  }
}
