import { LinkListItem } from '@vkph/components';
import {
  UiButton,
  UiEmpty,
  UiIcon,
  UiRow,
  useBreakpoint,
  UiList,
  message,
  UiModal,
  UiRender,
  UiRenderType,
} from '@vkph/ui';
import { useStore } from 'effector-react';
import React, { useCallback, useMemo, FC } from 'react';
import { useParams } from 'react-router-dom';

import { useAbstractStorage } from '@vkph/common/hooks';
import {
  createArrayMock,
  authService,
  getErrorResponseMessage,
  getFullNameWithoutPatronymic,
  getRoutePath,
  RouteNames,
} from '@vkph/common/utils';
import { useSpace } from '@vkph/ui/hooks';
import AddCircleIcon from '@vkph/ui/svg/add-circle.svg';
import RemoveCircleIcon from '@vkph/ui/svg/remove-circle.svg';
import {
  GetTimelinesAllRelationsCounterStorage,
  getFollowListStorage,
  SocialsInfoType,
} from '~profile/store/timelines';
import { FollowUser } from '~profile/typings/follow-user';

import { SubscribedEvent } from '../Profile';

const MOCKS_COUNT = 3;
const PAGE_SIZE = 10;
const usersMocks = createArrayMock(MOCKS_COUNT, (_, key) => <LinkListItem.Skeleton key={key} />);

type Props = {
  userName?: string;
  type: SocialsInfoType;
  onClose: () => void;
  counterStorage: GetTimelinesAllRelationsCounterStorage;
};

export const FollowList: FC<Props> = (props) => {
  const { type, onClose, counterStorage, userName } = props;
  const ownerId = authService.getCurrentUserId();
  const { id = '' } = useParams<{ id: string }>();
  const { lg: isLayoutLarge } = useBreakpoint();
  const { spaceM, spaceXL } = useSpace();
  const { followingsCountEvent } = counterStorage;
  const {
    storage: followListStorage,
    followUserEffect,
    unfollowUserEffect,
  } = useMemo(getFollowListStorage, []);

  const {
    data: followListData,
    loading: isFollowListLoading,
    pagination: followListPagination,
    fetchFx: fetchFeed,
  } = useAbstractStorage(followListStorage, {
    autoFetchAndRefetch: true,
    autoFetchParams: { pageNumber: 1, pageSize: PAGE_SIZE, userId: id, type },
    resetStoreOnUnmount: true,
    resetStoreOnChangeParams: { type, id },
    cancelPendingRequestOnUnmount: true,
  });

  const isFollowUserEffectPending = useStore(followUserEffect.pending);
  const isUnfollowUserEffectPending = useStore(unfollowUserEffect.pending);
  const isSubscribeButtonLoading = isFollowUserEffectPending || isUnfollowUserEffectPending;
  const isFollowListMocks = isFollowListLoading && !followListData.length;

  const followsEmptyMessage = useMemo(() => {
    const descriptionType = type === SocialsInfoType.Followers ? 'подписчиков' : 'подписок';

    return {
      header: 'Нет данных',
      description:
        id === ownerId
          ? `У вас нет ${descriptionType}`
          : `У пользователя ${userName} пока нет ${descriptionType}`,
    };
  }, [id, ownerId, type, userName]);

  const onSubscribeClick = (followUser: FollowUser) => {
    const subscribeEffect = followUser.isSubscribed ? unfollowUserEffect : followUserEffect;

    subscribeEffect(followUser).catch((e) => message.error(getErrorResponseMessage(e, 'Произошла ошибка')));

    if (id === ownerId) {
      followingsCountEvent({ type: followUser.isSubscribed ? SubscribedEvent.Remove : SubscribedEvent.Add });
    }
  };

  const onClickFetchFeeds = useCallback(
    () =>
      followListPagination.page
        ? fetchFeed({
            pageNumber: followListPagination.page + 1,
            pageSize: PAGE_SIZE,
            userId: id,
            type,
          })
        : null,
    [followListPagination],
  );

  const hasMore = Boolean(
    followListPagination.page &&
      followListPagination.count &&
      followListPagination.page < followListPagination.count,
  );
  const SubscribeButton: FC<{ followUser: FollowUser }> = (btnProps) => {
    const { followUser } = btnProps;
    const buttonLabel = followUser.isSubscribed ? 'Отписаться' : 'Подписаться';

    return (
      <UiRender type={UiRenderType.DisplayNone} visible={followUser.id !== ownerId}>
        <UiButton
          style={{ marginRight: 24 }}
          type={followUser.isSubscribed ? 'link-secondary' : 'link'}
          onClick={() => onSubscribeClick(followUser)}
          disabled={isSubscribeButtonLoading}
          label={isLayoutLarge ? buttonLabel : undefined}
          icon={
            <UiIcon
              width={20}
              height={20}
              component={followUser.isSubscribed ? RemoveCircleIcon : AddCircleIcon}
            />
          }
        />
      </UiRender>
    );
  };

  return (
    <UiModal.Content>
      {isFollowListMocks && usersMocks}
      {!isFollowListMocks && (
        <UiList
          split={false}
          locale={{ emptyText: <UiEmpty.Feed emptyMessage={followsEmptyMessage} /> }}
          rowKey={(user) => user.id}
          dataSource={followListData}
          renderItem={(user) => (
            <UiList.Item noStyle>
              <LinkListItem
                onClick={onClose}
                avatarProps={{ src: user.avatar }}
                to={getRoutePath(RouteNames.Profile, { id: user.id })}
                suffixedChildren={<SubscribeButton followUser={user} />}
                title={getFullNameWithoutPatronymic({ lastName: user.lastName, firstName: user.firstName })}
                subtitle={user.position?.name || ''}
                isActive={user.isActive}
              />
            </UiList.Item>
          )}
        />
      )}

      {hasMore && (
        <UiRow padding={isLayoutLarge ? spaceXL : spaceM}>
          <UiButton
            block
            size="large"
            type="secondary"
            label="Загрузить ещё"
            loading={isFollowListLoading}
            disabled={isFollowListLoading}
            onClick={onClickFetchFeeds}
          />
        </UiRow>
      )}
    </UiModal.Content>
  );
};
