import { AxiosResponse } from 'axios';

import { endpoints } from '@vkph/common/endpoints';
import { ProfileUserSettings } from '@vkph/common/types/models';
import { api } from '@vkph/common/utils';

export const getProfileUserSettings = () => endpoints.profiles.userSettings();

export const postProfileUserSettings = <T = unknown>(
  params: Partial<ProfileUserSettings>,
): Promise<AxiosResponse<T>> =>
  api.post({
    url: endpoints.profiles.userSettings(),
    data: { settings: { ...params } },
  });
