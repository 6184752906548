import { Layout, UiButton, UiEmpty } from '@vkph/ui';
import { useStore } from 'effector-react';
import isEqual from 'lodash/isEqual';
import React, { useEffect, FC, PropsWithChildren } from 'react';
import { useAuth, hasAuthParams } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';

import { useLocalStorage } from '@vkph/common/hooks';
import { settingsAuthConfigStorage } from '@vkph/common/store/settings';
import { SettingsAuthModel } from '@vkph/common/types/models';
import {
  api,
  authService,
  notificationEventListenersGlobal,
  useNotificationEventListenersGlobal,
} from '@vkph/common/utils';

import { AuthServiceProps } from '../../../typings';
import { getRedirectParams } from './utils';

const setAuthorizationCookie = (token?: string) => {
  document.cookie = `Authorization=${token}; path=/;`; // FIXME potential exploit
};

export const AuthOIDC: FC<PropsWithChildren<AuthServiceProps>> = (props) => {
  const { children, setUserId } = props;
  const navigate = useNavigate();

  const auth = useAuth();
  const userId = auth.user?.profile?.sub;

  const [settingsAuthConfigCached] = useLocalStorage<SettingsAuthModel | null>('settingsAuthData', null);
  const { data: settingsAuthConfigData } = useStore(settingsAuthConfigStorage.storage.store);

  const onRedirect = () => {
    const redirectParams = getRedirectParams();

    navigate(redirectParams, { replace: true });
    navigate(0);
  };

  useEffect(() => {
    if (
      settingsAuthConfigData &&
      settingsAuthConfigCached &&
      !isEqual(settingsAuthConfigCached, settingsAuthConfigData)
    ) {
      authService.logout();
    }
  }, [settingsAuthConfigData, settingsAuthConfigCached]);

  useNotificationEventListenersGlobal(notificationEventListenersGlobal);

  useEffect(() => {
    if (auth.user?.access_token) {
      setAuthorizationCookie(auth.user.access_token);
      authService.init(auth);
      api.setAuthParams({ token: authService.getToken(), type: authService.getAuthType() });
      // setupNotificationsService(); B2BCORE-13660 Фронт. Отключить WebSockets на портале
    }
  }, [auth.user?.access_token]);

  const isAutoSignIn = !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading;

  useEffect(() => {
    setUserId(userId);
  }, [userId]);

  useEffect(() => {
    if (!hasAuthParams() && isAutoSignIn) {
      auth.signinRedirect();
    }
  }, [isAutoSignIn, auth.signinRedirect]);

  if (auth.error) {
    return (
      <UiEmpty.Feed
        emptyMessage={{
          header: 'Произошла ошибка авторизации',
          description: <UiButton label="Обновить" type="link" onClick={onRedirect} />,
        }}
      />
    );
  }

  if (auth.isLoading || !auth.isAuthenticated || !userId) {
    return <Layout.Loading />;
  }

  return <>{children}</>;
};
